export const IntroSecDetails = {
  heading1: "Hello.",
  typeWritterHeadings: [
    "I'm Ed", 
    "I'm an entrepeneur",
    "I'm an innovator",
    "I'm a software generalist",
    "I'm a lifelong coder",
  ],
  heading2: "Based in Boston, Massachusetts.",
  btnText: "Download Resume",
};
export const AboutSecDetails = {
  image: {
    img: "images/about-me.jpg",
  },
  information: {
    title1: "About Me",
    name: "Ed Greenwood",
    desc1:
      "Emerging technology | Technical Fellow | Consultant | Full-stack Developer | Software Generalist | Entrepreneur & Founder | Innovator.",
    desc2:
      "My background is in computer science and software. This includes more than 20 years of experience as an entrepreneur, developer, and consultant. For the past ten years, I have been researching emerging and disruptive technology including blockchain, cryptocurrency, smart contracts, tokenization, web3, NFTs, AR, spatial computing, metaverses, AI, GenAI, and ML. I conduct “technical deep dives”, build prototypes, and write technical papers (before information is widely known) to innovate new products and services, share results, and make recommendations.",
  },
  moreAbout: [
    {
      num: 25,
      title: "Years Experience",
    },
    {
      num: 10,
      title: "Emerging Technologies",
    },
    {
      num: 30,
      title: "Technical Papers",
    },
  ],
};

export const ServicesDetails = {
  heading: {
    title: "Research",
    text: "For ten years, I have been researching advanced, emerging technologies. While there are dozens, my focus is on those most likely to cause widespread disruption. Some of the topics I researched are listed below.",
  },
  details: [
    {
      name: "Blockchain",
      desc: "A decentralized database that maintains a continuously growing list of records known as blocks.",
      icon: "fas fa-network-wired",
    },
    {
      name: "Cryptocurrency",
      desc: "A digital asset, secured by cryptography, that can be used as a medium of exchange, traded, or sold.",
      icon: "fas fa-coins",
    },
    {
      name: "Smart Contracts",
      desc: "A self-executing contract (computer program) that runs on a blockchain; they can’t be blocked or censored.",
      icon: "fas fa-code",
    },
    {
      name: "Tokenization",
      desc: "Creating a digital representation of a digital or real-world asset, then recording it on a blockchain.",
      icon: "fas fa-laptop-house",
    },
    {
      name: "Web3",
      desc: "Third phase in evolution of the web focused on decentralization (uses blockchain, crypto, NFTs, and AI).",
      icon: "fas fa-globe",
    },
    {
      name: "Metaverse",
      desc: "A web-powered space that uses AR and VR to create an immersive, virtual, 3-D, shared universe.",
      icon: "fas fa-vr-cardboard",
    },
    {
      name: "Non-fungible tokens (NFTs)",
      desc: "A cryptocurrency that represents ownership of a unique digital or real-world asset.",
      icon: "fas fa-image",
    },
    {
      name: "Augmented Reality (AR)",
      desc: "Using software to superimpose digital information on your real-world view via glasses, goggles, or a headset.",
      icon: "fas fa-vr-cardboard",
    },
    {
      name: "Virtual Reality (VR)",
      desc: "Digitally recreating your entire world view using software via glasses, goggles, or a headset.",
      icon: "fas fa-vr-cardboard",
    },
    {
      name: "Artificial Intelligence (AI)",
      desc: "Technology that allows computers to simulate human learning, comprehension, and decision making.",
      icon: "fas fa-brain",
    },
    {
      name: "Generative AI (GenAI)",
      desc: "A new branch of AI that generates elaborate, human-like responses to natural language prompts.",
      icon: "fas fa-brain",
    },
    {
      name: "Machine Learning (ML)",
      desc: "A branch of AI that uses data and algorithms to replicate the way humans learn without programming.",
      icon: "fas fa-brain",
    },
    {
      name: "Gamification",
      desc: "Adding elements of game playing to other areas, to engage and motivate people to achieve goals.",
      icon: "fas fa-gamepad",
    },
    {
      name: "Mobile Devices and Wearables",
      desc: "Mobile, handheld, and wearable devices including smartphones and smartwatches.",
      icon: "fas fa-mobile",
    },
    {
      name: "Platforms",
      desc: "A business model that uses technology to connect people, organizations, and resources.",
      icon: "fas fa-layer-group",
    },

  ],
};

export const MyWorking_Skills = {
  heading: {
    title: "Skills",
    text: "As a software generalist and lifelong coder, I have more than 45 qualified skills.",
  },
  video: {
    vdo1: "https://www.youtube.com/watch?v=3iCYeO_4HEg",
  },
  working: [
    {
      name: "Prompt Engineering",
      icon: "fas fa-wrench",
      desc: "For ChatGPT, DALL-E, and others; includes prompt splitting and chaining.",
    },
    {
      name: "SEO",
      icon: "fas fa-wrench",
      desc: "Advanced search engine optimization for Google and Bing.",
    },
    {
      name: "E-Commerce",
      icon: "fas fa-wrench",
      desc: "Sold subscriptions, digital downloads, and packaged software online.",
    },
    {
      name: "Software as a Service",
      icon: "fas fa-wrench",
      desc: "Created multiple web-apps, including apps that can be private-labeled.",
    },
    {
      name: "Brainstorming",
      icon: "fas fa-wrench",
      desc: "Brainstormed new products, services, improvements, and ideas.",
    },
    {
      name: "Innovation",
      icon: "fas fa-wrench",
      desc: "Invented new products, services, and methods based on emerging technology.",
    },
    {
      name: "Entrepreneurship",
      icon: "fas fa-wrench",
      desc: "Started and ran a software company for over 20 years.",
    },
    {
      name: "Web Scraping",
      icon: "fas fa-wrench",
      desc: "Developed proprietary web-scraping software to retrieve data from large websites.",
    },
    {
      name: "Website Development",
      icon: "fas fa-wrench",
      desc: "Designed and created multiple websites using WordPress, React, Bootstrap, HTML, and HTML5.",
    },
    {
      name: "Web Apps",
      icon: "fas fa-wrench",
      desc: "Created custom web apps using HTML, HTML5, and React.",
    },
    {
      name: "Mobile Apps",
      icon: "fas fa-wrench",
      desc: "Developed mobile apps for iOS and watchOS.",
    },
    {
      name: "Desktop Apps",
      icon: "fas fa-wrench",
      desc: "Developed apps for Windows and macOS; includes using cloud services.",
    },
    {
      name: "Prototyping",
      icon: "fas fa-wrench",
      desc: "Created mobile, desktop, and web app prototypes to quickly test new concepts.",
    },
    {
      name: "Algorithms",
      icon: "fas fa-wrench",
      desc: "20 years of experience developing algorithms for software.",
    },
    {
      name: "User Interface Design",
      icon: "fas fa-wrench",
      desc: "Designed user interfaces for web, desktop, and mobile apps including the Apple Watch.",
    },
    {
      name: "Full-Stack Development",
      icon: "fas fa-wrench",
      desc: "Experienced building front and back end of applications (includes all layers).",
    },
    {
      name: "Software Development",
      icon: "fas fa-wrench",
      desc: "Developed software for almost every major platform.",
    },
    {
      name: "Software Design",
      icon: "fas fa-wrench",
      desc: "Designed software for every major platform.",
    },
    {
      name: "Artificial Intelligence",
      icon: "fas fa-wrench",
      desc: "Created and trained custom models for use in applications.",
    },
    {
      name: "Generative AI",
      icon: "fas fa-wrench",
      desc: "Created prototypes for several novel GenAI use-cases.",
    },
    {
      name: "Machine Learning",
      icon: "fas fa-wrench",
      desc: "Added intelligence to apps (like an image classifier).",
    },
    {
      name: "Gamification",
      icon: "fas fa-wrench",
      desc: "Added elements of game playing to apps to engage and motivate users.",
    },
    {
      name: "Metaverses",
      icon: "fas fa-wrench",
      desc: "Researched top 15 metaverses (created a detailed, 15 x 25 comparison matrix).",
    },
    {
      name: "Web3",
      icon: "fas fa-wrench",
      desc: "Researched web3 and the components that drive it.",
    },
    {
      name: "Augmented Reality",
      icon: "fas fa-wrench",
      desc: "Developed multiple prototypes for iOS and Microsoft HoloLens.",
    },
    {
      name: "Virtual Reality",
      icon: "fas fa-wrench",
      desc: "Developed multiple prototypes for iOS including portals.",
    },
    {
      name: "Non-Fungible Tokens",
      icon: "fas fa-wrench",
      desc: "Created minting service to deploy dynamic NFTs on Ethereum.",
    },
    {
      name: "Smart Contracts",
      icon: "fas fa-wrench",
      desc: "Developed smart contracts on Ethereum, Hypledger, and other platforms.",
    },
    {
      name: "Decentralized Apps",
      icon: "fas fa-wrench",
      desc: "Created prototypes for decentralized apps (dApps) on Ethereum.",
    },
    {
      name: "Cryptocurrency",
      icon: "fas fa-wrench",
      desc: "Researched crypto for two banks and created a custom cryptocurrency.",
    },
    {
      name: "Ethereum",
      icon: "fas fa-wrench",
      desc: "Developed blockchain prototypes and wrote multiple technical papers.",
    },
    {
      name: "Blockchain",
      icon: "fas fa-wrench",
      desc: "Researched the top blockhain platforms for two banks.",
    },
    {
      name: "Distributed Ledgers",
      icon: "fas fa-wrench",
      desc: "Researched distributed ledger technology (DLTs) for two banks.",
    },
    {
      name: "Java",
      icon: "fas fa-wrench",
      desc: "Programming language.",
    },
    {
      name: "JavaScript",
      icon: "fas fa-wrench",
      desc: "Programming language (mainly use to develop web apps).",
    },
    {
      name: "C",
      icon: "fas fa-wrench",
      desc: "Programming language.",
    },
    {
      name: "C++",
      icon: "fas fa-wrench",
      desc: "Programming language.",
    },
    {
      name: "Objective-C",
      icon: "fas fa-wrench",
      desc: "Programming language (mainly use to develop iOS apps).",
    },
    {
      name: "Swift",
      icon: "fas fa-wrench",
      desc: "Programming language (mainly use to develop iOS apps).",
    },
    {
      name: "Python",
      icon: "fas fa-wrench",
      desc: "Programming language (mainly use for ML and web scraping).",
    },
    {
      name: "Solidity",
      icon: "fas fa-wrench",
      desc: "Programming language (use for coding on Ethereum blockchain).",
    },
    {
      name: "PHP",
      icon: "fas fa-wrench",
      desc: "Programming language.",
    },
    {
      name: "Writing",
      icon: "fas fa-wrench",
      desc: "I have written over 30 technical papers in the past nine years.",
    },
    {
      name: "Public Speaking",
      icon: "fas fa-wrench",
      desc: "I regularly speak about emerging and disruptive technology.",
    },
    {
      name: "Presentations",
      icon: "fas fa-wrench",
      desc: "Created multiple presentations to share research and make recommendations.",
    },
  ],
  skills: [
    {
      skill: "Innovation and Brainstorming",
      percent: 95,
    },
    {
      skill: "AI, AR, and Blockchain",
      percent: 95,
    },
    {
      skill: "Writing and Public Speaking",
      percent: 95,
    },
   {
      skill: "Software Development and Consulting",
      percent: 95,
    },
  ],
};

export const CallToActionDetails = {
  heading: "For consulting, advisory services, and innovation.",
  subHeading: "I have 25 years of experience in software and innovation, and 20 as an entrepreneur.",
  btnText: "Contact Me",
};

export const ContactDetails = [
  {
    icon: "fas fa-map-marker-alt",
    title: "My Location",
    p1: "Boston, Massachusetts",
    p2: "",
  },
  {
    icon: "fas fa-phone-alt",
    title: "Phone",
    p1: "Phone: (617) 468-8033",
    p2: "",
  },
  {
    icon: "fas fa-envelope",
    title: "Inquiries",
    p1: "info At edwardgreenwood.com",
    p2: "",
  },
];

export const socialIcons = [
  {
    title: "Twitter",
    icon: "fab fa-twitter",
    href: "",
    className: "social-icons-twitter",
  },
  {
    title: "Facebook",
    icon: "fab fa-facebook-f",
    href: "",
    className: "social-icons-facebook",
  },
  {
    title: "Linkedin",
    icon: "fab fa-linkedin-in",
    href: "https://www.linkedin.com/in/ed-greenwood-7a848613",
    className: "social-icons-linkedin",
  },
  {
    title: "Dribbble",
    icon: "fab fa-dribbble",
    href: "",
    className: "social-icons-dribbble",
  },
  {
    title: "GitHub",
    icon: "fab fa-github",
    href: "",
    className: "social-icons-github",
  },
];