import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = () => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const htmlElement = document.getElementsByTagName("html")[0];
  const isRtl = htmlElement.getAttribute("dir") === "rtl";

  const filters = {
    DESKTOP_APP: "Desktop Apps",
    MOBILE_APP: "Mobile Apps",
    WEB_APP: "Web Apps",
    DOCUMENT: "Documents",
  };

  const types = {
    IMAGE: "image",
    VIDEO: "video",
    DOCUMENT: "document",
  };

  const projectsData = [
    {
      title: "30-Tool Fitness App",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Over several years, I developed an original fitness and nutrition tracking app with 30 tools, digital maps (to map runs in advance), a 550,000-food database, and 12 fitness calculators. For home, business, and education, it's designed to track one person or large groups. Before this, I created an app for runners that received “Editor's Pick” five times, was included on PC Magazine's monthly CD, recommended by Runner's World, E-Gear and Business Start-Ups magazine, and was on USA Today and Family PC's top ten list.",
        client: "Edward A. Greenwood LLC",
        technologies: "Java, PHP, MySQL",
        industry: "Fitness and nutrition",
        date: "July 15, 2024",
        url: {
          name: "www.weightmania.com",
          link: "https://www.weightmania.com",
        },

        sliderImages: [          
          "images/projects/wm74Calculators.png",
          "images/projects/wm74MealScreen.png",
          "images/projects/wm74ChartScreen.png",
          "images/projects/wm74CardioScreen.png",
        ],
      },

      thumbImage: "images/projects/wm74HomeScreen.png",

      categories: [filters.DESKTOP_APP],
    },

    {
      title: "Apple Watch App for Bank",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "An Apple Watch Series Five 44mm screen depicting a live bank account balance and charts. This is a prototype I created for a ten-screen Apple Watch app. One of the innovative features is that user's don't need to repeatedly sign into their account. The initial screen is color-coded to indicate a high or low balance.",
        client: "Eastern Bank",
        technologies: "Swift, Objective-C, APIs",
        industry: "Financial services (fintech)",
        date: "May, 2020",
        url: {
        },

        sliderImages: [   
          "images/projects/appleWatchBankAppTwo.png",       
        ],
      },

      thumbImage: "images/projects/appleWatchBankAppOne.png",

      categories: [filters.MOBILE_APP],
    },

    {
      title: "Digital Bank on Blockchain",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "This is a dashboard (a web app prototype) for a digital bank on the Ethereum blockchain.",
        client: "Eastern Bank",
        technologies: "HTML5, Node.js, Express.js, MySQL, web3, Ethereum blockchain",
        industry: "Financial services (fintech)",
        date: "2020",
        url: {
          name: "Play video",
          link: "images/projects/blockchainDemoStandardVersion.mp4",
        },

        sliderImages: [          
         
        ],
      },

      thumbImage: "images/projects/digitalBankOnBlockchain.png",

      categories: [filters.WEB_APP],
    },

    {
      title: "Augmented Reality (3-D models)",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "By extending Apple's AR framework (and making improvements), I developed iOS apps in Swift and Objective-C that render 3-D models at 60 frames-per-second, simulate gravity, can be saved, and more.",
        technologies: "Swift, Objective-C, and C",
        client: "Eastern Bank",
        industry: "Financial services (fintech)",
        date: "May, 2024",
        url: {
          name: "Play video",
          link: "images/projects/augmentedRealityGreenRobot.mp4"
        },

        sliderImages: [   
              
        ],
      },

      thumbImage: "images/projects/augmentedRealityGreenRobot.png",

      categories: [filters.MOBILE_APP],
    },

    {
      title: "Augmented Reality (Outdoor Maps)",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "This example shows an outdoor mapping I developed. By extending Apple's AR framework (and making improvements), I developed iOS apps in Swift and Objective-C that render 3-D models at 60 frames-per-second, simulate gravity, can be saved, and more.",
        technologies: "Swift, Objective-C, and C",
        client: "Eastern Bank",
        industry: "Financial services (fintech)",
        date: "January, 2020",
        url: {
          name: "Play video",
          link: "images/projects/augmentedRealityOutdoorMaps.mp4"
        },

        sliderImages: [   
              
        ],
      },

      thumbImage: "images/projects/augmentedRealityOutdoorMaps.png",

      categories: [filters.MOBILE_APP],
    },

    {
      title: "Augmented Reality (3-D models)",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "This example is depicting a vintage television with Eastern Bank branding. By extending Apple's AR framework (and making improvements), I developed iOS apps in Swift and Objective-C that render 3-D models at 60 frames-per-second, simulate gravity, can be saved, and more.",
        technologies: "Swift, Objective-C, and C",
        client: "Eastern Bank",
        industry: "Financial services (fintech)",
        date: "January, 2020",
        url: {
          name: "Play video",
          link: "images/projects/augmentedRealityRetroTV.mp4"
        },

        sliderImages: [   
              
        ],
      },

      thumbImage: "images/projects/augmentedRealityRetroTV.png",

      categories: [filters.MOBILE_APP],
    },
    {
      title: "Augmented Reality (3-D models)",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "For this prototype, I created a “tiny town” complete with a main street and multiple buildings. By extending Apple's AR framework (and making improvements), I developed iOS apps in Swift and Objective-C that render 3-D models at 60 frames-per-second, simulate gravity, can be saved, and more.",
        technologies: "Swift, Objective-C, and C",
        client: "Eastern Bank",
        industry: "Financial services (fintech)",
        date: "August, 2022",
        url: {
          name: "Play video",
          link: "images/projects/augmentedRealityTinyTown.mp4"
        },

        sliderImages: [   
              
        ],
      },

      thumbImage: "images/projects/augmentedRealityTinyTown.png",

      categories: [filters.MOBILE_APP],
    },

    {
      title: "Augmented Reality (Videos on Labels)",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "In this prototype, I used machine learning to train a model (an image classifier) to recognize several different labels. The app selects one frame from a video at at time, then scales, rotates, crops, and superimposes it over a label at 60 frames per second. Notice how it still works when the label is rotated in real-time? My latest version can handle over a dozen labels at the same time, each using a different video.",
        technologies: "Swift, Objective-C, and C",
        client: "Eastern Bank",
        industry: "Financial services (fintech)",
        date: "January, 2020",
        url: {
          name: "Play video",
          link: "images/projects/augmentedRealityVideosOnLabel.mp4"
        },

        sliderImages: [   
              
        ],
      },

      thumbImage: "images/projects/augmentedRealityVideosOnLabel.png",

      categories: [filters.MOBILE_APP],
    },

    {
      title: "Augmented Reality (3-D portals)",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "This prototype demonstates something considered very challenging to do in AR. It renders an immersive, 3-D portal with a 360° spherical video, 3-D models, and occlusion.",
        technologies: "Swift, Objective-C, and C",
        client: "Eastern Bank",
        industry: "Financial services (fintech)",
        date: "December, 2023",
        url: {
          name: "Play video",
          link: "images/projects/augmentedReality3DPortal.mp4"
        },

        sliderImages: [   
              
        ],
      },

      thumbImage: "images/projects/augmentedReality3DPortal.png",

      categories: [filters.MOBILE_APP],
    },

    {
      title: "Social Media Dashboard",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "A dashboard (web app) depicting public sentiment on social media from multiple sources. This is a custom, HTML5 dashboard with no third-party libraries or frameworks. Data was obtained from multiple public websites using my own custom web scraping tools that circumvent techniques to block automated tools.",
        client: "Eastern Bank",
        technologies: "HTML5, CSS, Python, MySQL, APIs",
        industry: "Financial services (fintech)",
        date: "April 17, 2020",
        url: {
          name: "www.weightmania.com",
          link: "https://www.weightmania.com",
        },

        sliderImages: [          
          "images/projects/socialMediaDashboardTwo.png",         
        ],
      },

      thumbImage: "images/projects/socialMediaDashboardOne.png",

      categories: [filters.WEB_APP],
    },

    {
      title: "Word Cloud Visualization",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "This screen depicts an original word cloud visualization I created using HTML5. My app uses Scalable Vector Graphics (SVG) to improve quality, millions of colors, and it can process large volumes of text. Unlike most word clouds, it also includes phrases. The first slide is summarizing survey responses from thousands of users while the second summarizes tweets about Eastern Bank retrieved from Twitter's API. Note, the latter uses only three colors — dark blue, light blue, and orange.",
        client: "Eastern Bank",
        technologies: "HTML5, D3, SVG, MySQL",
        industry: "Financial services (fintech)",
        date: "February 17, 2021",
        url: {
        
        },

        sliderImages: [   
          "images/projects/twitterWordCloud.png",       
        ],
      },

      thumbImage: "images/projects/surveyWordCloudOne.png",

      categories: [filters.WEB_APP],
    },

    {
      title: "Instant Payments via Blockchain",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "Architecture diagrams I created for Eastern Bank to demonstrate a low-cost instant payment platform based on a private version of the Ethereum blockchain. I backed this research with a 30-page technical paper and multiple prototypes. Provides a low alternative to FedNow and Zelle.",
        client: "Eastern Bank",
        technologies: "HTML5, Node.js, Express.js, MySQL, web3, Ethereum blockchain",
        industry: "Financial services (fintech)",
        date: "February 17, 2021",
        url: {
          name: "www.weightmania.com",
          link: "https://www.weightmania.com",
        },

        sliderImages: [   
          "images/projects/ethereumArchitectureDiagram.png",       
        ],
      },

      thumbImage: "images/projects/ethereumInstantPaymentsArchitectureDiagram.png",

      categories: [filters.DOCUMENT],
    },
    {
      title: "Blockchain Comparison Matrix",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "This is an original 7 x 35 matrix I created to compare the top blockchain platforms in 2019. In a time-consuming process, it required installing each platform and testing it against dozens of metrics. I recently created a more comprehensive, updated version that compares the top-rated 15 blockchain platforms.",
        client: "Eastern Bank",
        technologies: "Blockchain",
        industry: "Financial services (fintech)",
        date: "2019",
        url: {
          name: "blockchainComparisonMatrix.pdf",
          link: "images/projects/edwardGreenwoodblockchainComparisonMatrixV4.pdf",
        },

        sliderImages: [
          "images/projects/ethereumArchitectureDiagram.png",  
        ],
      },

      thumbImage: "images/projects/blockchainComparisonMatrix.png",

      categories: [filters.DOCUMENT],
    },

    {
      title: "Metaverse Comparison Matrix",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "The first slide is depicting the Sandbox, one of the metaverses I researched, while the latter is an original, 15 x 25 poster-sized matrix comparing the top-rated (and most popular) metaverses. I tested 15 different platforms against 25 industry standards. A metaverse is a web-powered space that uses AR and VR to create an immersive, virtual, 3-D, shared universe; users can share experiences, interact via digital avatars, buy, sell, trade, and create tokenized assets, start businesses, and explore.",
        client: "Eastern Bank",
        technologies: "Web3",
        industry: "Financial services (fintech)",
        date: "November, 2022",
        url: {
          name: "metaverseComparisonMatrix.pdf",
          link: "images/projects/edwardGreenwoodMetaverseComparisonMatrix.pdf",
        },

        sliderImages: [
          "images/projects/metaverseComparisonMatrix.png", 
        ],
      },

      thumbImage: "images/projects/sandboxMetaverseScreenshot.png",

      categories: [filters.DOCUMENT],
    },

    {
      title: "Yale University Video Game",
      type: types.DOCUMENT,
      document: {
        projectInfo:
          "I created a prototype for an original, adaptive, multi-part video game for the Yale University Child Center (Yale School of Medicine) to test children for analytical, practical, and creative intelligence. To bypass restrictions distributing it through an app store, I designed and coded the entire game in HTML5. While it's designed for tablets, it runs in any browser on any desktop or mobile device. It also employs algorithms and proprietary techniques to circumvent browser restrictions, optimize caching, animate and move sprites, process complex screen gestures, minimize bandwidth, reduce power consumption, and match the performance of native iPhone and Android apps.",
        client: "Yale University",
        technologies: "HTML5, PHP, MySQL",
        industry: "Education (edtech)",
        date: "May, 2015",
        url: {
        },

        sliderImages: [   
          "images/projects/yaleUniversityVideoGameScreenTwo.jpg",       
        ],
      },

      thumbImage: "images/projects/yaleUniversityVideoGameScreenOne.jpg",

      categories: [filters.WEB_APP],
    },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
      originLeft: !isRtl,
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, [isRtl]);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  const getKeyByValue = (value) => {
    return Object.keys(filters).find((key) => filters[key] === value);
  };

  const getFilterClasses = (categories) => {
    if (categories.length > 0) {
      let tempArray = [];
      categories.forEach((category, index) => {
        tempArray.push(getKeyByValue(category));
      });
      return tempArray.join(" ");
    }
  };

  return (
    <>
      <section id="portfolio" className={"section"}>
        <div className={"container"}>
          {/* Heading */}
          <div className="row mb-5 wow fadeInUp">
            <div className="col-lg-9 col-xl-8 mx-auto text-center">
              <h2 className="fw-600 mb-3">Portfolio</h2>
              <hr className="heading-separator-line border-primary border-2 opacity-10 mx-auto" />
              <p className="text-4 text-muted">Sample prototypes, visualizations, charts, and technical papers that demonstrate my work. My focus is on innovation and researching emerging technologies.
              </p>
            </div>
          </div>

          {/* Heading end*/}
          {/* Filter Menu */}
          <ul className="portfolio-menu nav nav-pills text-uppercase justify-content-center border-bottom-0 mb-5 wow fadeInUp">
            <li className="nav-item">
              <button
                className={
                  "nav-link rounded-pill " + (filterKey === "*" ? "active" : "")
                }
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link rounded-pill " +
                    (filterKey === oneKey ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(oneKey)}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio wow fadeInUp">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      getFilterClasses(project.categories)
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div
                          className="portfolio-overlay"
                          onClick={() => {
                            setSelectedProjectDetails(projectsData[index]);
                            setIsOpen(true);
                          }}
                        >
                          <button className="popup-ajax stretched-link border-0 p-0 "></button>
                          <div className="portfolio-overlay-details">
                            <p className="text-white text-6">
                              {project.type === types.DOCUMENT && (
                                <i className="fas fa-file-alt"></i>
                              )}
                              {project.type === types.IMAGE && (
                                <i className="fas fa-image"></i>
                              )}
                              {project.type === types.VIDEO && (
                                <i className="fas fa-video"></i>
                              )}
                            </p>
                            <h5 className="text-white fw-400">
                              {project?.title}
                            </h5>
                            <span className="text-light">Click to Open</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      {/* Modal */}
      {isOpen && (
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          setIsOpen={setIsOpen}
        ></ProjectDetailsModal>
      )}
    </>
  );
};

export default Portfolio;
