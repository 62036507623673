export const lineParticles = {
    fullScreen: {
       enable: false,
   },
   particles: {
       color: {
           value: "#ffffff",
         },
       number: {
           value: 80,
         },
       links: {
           color: "#ffffff",
           enable: true,
           opacity: 0.6,
       },
       move: {
          direction: "none",
          enable: true,
          outModes: {
            default: "bounce",
          },
          random: false,
          speed: 1,
          straight: false,
       },
       opacity: {
           value: 0.6,
       },
       shape: {
          type: "circle",
       },
       size: {
           value: { min: 1, max: 5 },
       },
   },
   detectRetina: true,
 }
 