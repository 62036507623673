import React from "react";
import SocialLinks from "../commonComponents/SocialLinks";

const Footer = () => {
  return (
    <footer id="footer" className="section bg-dark" data-bs-theme="dark">
      <div className="container wow fadeInUp">
        <ul className="social-icons social-icons-lg social-icons-muted justify-content-center mb-3">
          <SocialLinks />
        </ul>
       <div>
  <p className="text-muted text-center">
    Copyright © 2024 Edward Greenwood. All Rights Reserved.
  </p>
</div>

      </div>
    </footer>
  );
};

export default Footer;
